import React, { useEffect } from 'react';
import './App.css';
import Home from './pages/home';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import BusList from './pages/busList';
import PassengerDetails from './pages/passanger';
import TicketConfirmation from './pages/confim_ticket';
import ProfileDetails from './pages/profile';
import RegisterDetail from './pages/register';
import LoginDetail from './pages/login';
import ForgotPasswordDetail from './pages/forgot-password';
import AboutUs from './pages/aboutUs';
import PassengerView from './pages/passangerView';
import ContactUs from './pages/contact';
import TicketsDetailsView from './pages/tickets-details';
import BlogPage from './pages/blog-page';
import SingleBlog from './pages/singleBlog';
import PreviousBooking from './components/profile/previosbooking';
import CancelTicket from './components/profile/cancel-ticket';
import ShowMyTicket from './components/profile/show-my-ticket';
import ShippingPolicy from './components/profile/shipping-policy';
import TermsConditions from './components/profile/terms-and-conditions';
import CancelPolicy from './components/profile/cancel-policy';
import PrivacyPolicy from './components/profile/privacy-policy';
import Faq from './components/profile/faq';
import Wallet from './components/profile/wallet';


function App() {

  function usePageTitle(title) {
    useEffect(() => {
      document.title = title;
    }, [title]);
  }

  // Title-aware Route Wrapper
  function TitleRoute({ title, children, ...rest }) {
    usePageTitle(title); // Set document title dynamically
    return <Route {...rest}>{children}</Route>;
  }

  return (
    <>


      {/* <Chat /> */}
      <Router>
        <Switch>
          <TitleRoute exact path='/' title="Sarthi Bus - sarthibus.com">
            <Home />
          </TitleRoute >
          <TitleRoute path='/bus-list' title="Bus List - sarthibus.com" >
            <BusList />
          </TitleRoute>
          <TitleRoute path='/passange-details' title="Passenger Details - sarthibus.com">
            <PassengerDetails />
          </TitleRoute>
          <TitleRoute path='/ticket-view' title="Ticket Confirmation - sarthibus.com">
            <TicketConfirmation />
          </TitleRoute>
          {/* {localStorage.getItem('UserID') ?
            <Route path='/profile/edit-profile'>
              <ProfileDetails />
            </Route> :
            <Route path='/profile/support'>
              <ProfileDetails />
            </Route>
          } */}

          <TitleRoute path="/profile" component={ProfileDetails} title="Profile - sarthibus.com" />
          <TitleRoute path="/profile/edit-profile" component={ProfileDetails} title="Profile - sarthibus.com" />
          <TitleRoute path="/profile/support" component={ProfileDetails} title="Profile - sarthibus.com" />
          <TitleRoute path="/profile/wallet" component={Wallet} title="Profile - sarthibus.com" />
          <TitleRoute path="/profile/previous-booking" component={ProfileDetails} title="Profile - sarthibus.com" />
          <TitleRoute path="/profile/cancel-ticket" component={ProfileDetails} title="Profile - sarthibus.com" />
          <TitleRoute path="/profile/show-my-ticket" component={ProfileDetails} title="Profile - sarthibus.com" />
          <TitleRoute path="/profile/shipping-delivery" component={ProfileDetails} title="Profile - sarthibus.com" />
          <TitleRoute path="/profile/terms-conditions" component={ProfileDetails} title="Profile - sarthibus.com" />
          <TitleRoute path="/profile/cancel-refund-policy" component={ProfileDetails} title="Profile - sarthibus.com" />
          <TitleRoute path="/profile/privacy-policy" component={ProfileDetails} title="Profile - sarthibus.com" />
          <TitleRoute path="/profile/faq" component={ProfileDetails} title="Profile - sarthibus.com" />
          <TitleRoute path='/sign-up' title="Register - sarthibus.com">
            <RegisterDetail />
          </TitleRoute>
          <TitleRoute path='/login' title="Login - sarthibus.com">
            <LoginDetail />
          </TitleRoute>
          <TitleRoute path='/forgot-password' title="Reset Pasword - sarthibus.com">
            <ForgotPasswordDetail />
          </TitleRoute>
          <TitleRoute path='/about-us' title="About - sarthibus.com">
            <AboutUs />
          </TitleRoute >
          <TitleRoute path='/passnger-detail-view' title="Passenger View - sarthibus.com">
            <PassengerView />
          </TitleRoute>
          <TitleRoute path='/contact-us' title="Conact Us - sarthibus.com">
            <ContactUs />
          </TitleRoute>
          <TitleRoute path='/ticket-details-view/:type/:id' title="Ticket Details - sarthibus.com">
            <TicketsDetailsView />
          </TitleRoute>
          <TitleRoute path='/blog-details' title="Blog Details - sarthibus.com">
            <BlogPage />
          </TitleRoute>
          <TitleRoute path='/single-blog/:id' title="Single Blog - sarthibus.com">
            <SingleBlog />
          </TitleRoute>
          {/* <Route path='/support'>
            <Supports />
          </Route>
          <Route path='/terms-conditions'>
            <TermsConditions />
          </Route>
          <Route path='/privacy-policy'>
            <PrivacyPolicy />
          </Route>
          <Route path='/cancel-policy'>
            <CancelPolicy />
          </Route> */}
          {/* <Route path='/shipping-refund-policy'>
            <ShippingPolicy />
          </Route> */}
        </Switch>
      </Router>
    </>
  );
}

export default App;
